import React, { useContext } from 'react';
import SyncWalletIcon from '../assets/sync-wallet.svg';
import { Card } from '../common';
import constants from '../constants/messages';
import { NEED_HELP_FOR_SYNC_URL } from '../constants/redirectUrls';
import { TokenSwapContext } from '../context/TokenSwapContext';
import { handleSyncSignVechainAddress } from '../helpers/vechain';

function WalletSignIn() {
  const context = useContext(TokenSwapContext);

  const handleSyncConnect = async () => {
    let { signer } = await handleSyncSignVechainAddress();

    context.setVechainAddress(signer);
  };

  return (
    <Card className="ts-sync-card">
      <h2 className="ts-connect-the-wallet">
        {constants.CONNECT_TO_SYNC_WALLET_TITLE}
      </h2>
      <p className="ts-agree-terms-text">
        {`${constants.CONNECTING_YOUR_SYNC_WALLET_TEXT} `}
        <a
          target="_blank"
          rel="noreferrer"
          href={process.env.REACT_APP_JUR_TERMS_LINK}
        >
          {constants.JUR_TERMS_TITLE}
        </a>
        {` and `}
        <a
          target="_blank"
          rel="noreferrer"
          href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
        >
          {constants.PRIVACY_POLICY_TITLE}
        </a>
      </p>
      {SyncButton(handleSyncConnect)}
      <p className="ts-need-help-text">
        <a target="_blank" rel="noreferrer" href={NEED_HELP_FOR_SYNC_URL}>
          {constants.NEED_HELP_TEXT}
        </a>
        {` ${constants.SIGNING_UP_FOR_SYNC2}`}
      </p>
    </Card>
  );
}

export default WalletSignIn;

const SyncButton = (handleSyncConnect) => (
  <div className="ts-sync-button" onClick={handleSyncConnect}>
    <div className="ts-sync-button-text">Sync 2.0</div>
    <div>
      <img src={SyncWalletIcon} alt="SyncWalletIcon" />
    </div>
  </div>
);
