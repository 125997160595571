import { Col, Collapse, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import FaqCrossIcon from '../assets/faq-cross.svg';
import FaqPlusIcon from '../assets/faq-plus.svg';
import Logo from '../assets/logo.svg';
import Step1ActiveIcon from '../assets/step-1-graphic-active.svg';
import Step2ActiveIcon from '../assets/step-2-graphic-active.svg';
import Step3ActiveIcon from '../assets/step-3-graphic-active.svg';
import constants, {
  FAQ_TEXT,
  JUR_MOVING_TO_DOT_TEXT,
  LOCK_TOKENS_TO_SWAP,
} from '../constants/messages';
import data from '../resources/FAQlist.json';
import WalletSignIn from './WalletSignIn';

const { Panel } = Collapse;

const DEFAULT_ACTIVE_GRAPHIC = 1;
const DEFAULT_COLLAPSE_ACTIVE_KEY = ['0'];
const DEFAULT_GRAPHIC_TIMEOUT_DELAY = 2000;

function Home() {
  const [currentActiveGraphic, setCurrentActiveGraphic] = useState(
    DEFAULT_ACTIVE_GRAPHIC
  );

  const assets = [
    {
      icon: Step1ActiveIcon,
      step: constants.STEP_ONE_TEXT,
      desc: constants.LOCK_EXISTING_JUR_TOKENS_ON_VECHAIN,
    },
    {
      icon: Step2ActiveIcon,
      step: constants.STEP_TWO_TEXT,
      desc: constants.APPROVAL_IN_PROGRESS,
    },
    {
      icon: Step3ActiveIcon,
      step: constants.STEP_THREE_TEXT,
      desc: constants.CLAIM_NEW_JUR_TOKENS_ON_POLKADOT,
    },
  ];

  const handleStartGraphic = useCallback(() => {
    const newActiveGraphic =
      currentActiveGraphic < 3 ? currentActiveGraphic + 1 : 1;
    // this is to update the currentActiveGraphic so that the corresponding graphic will be activated and rest are disabled
    setCurrentActiveGraphic(newActiveGraphic);
  }, [currentActiveGraphic]);

  useEffect(() => {
    setTimeout(handleStartGraphic, DEFAULT_GRAPHIC_TIMEOUT_DELAY);
  }, [currentActiveGraphic, handleStartGraphic]);

  const Footer = () => {
    return (
      <div className="ts-home-footer">
        <h2>{constants.FOOTER_TEXT}</h2>
      </div>
    );
  };

  const LoggedOutNavbar = () => {
    return (
      <div className="ts-navbar">
        <a href="/">
          <img src={Logo} alt="Jur Logo" />
        </a>
      </div>
    );
  };

  return (
    <>
      <div className="ts-home-wrapper">
        <LoggedOutNavbar />
        <div className="ts-infographic-wrapper">
          <h1 className="ts-infographic-title">{JUR_MOVING_TO_DOT_TEXT}</h1>
          <h2>{LOCK_TOKENS_TO_SWAP}</h2>

          <div className="ts-infographic-overview">
            <div className="ts-infographic">
              {assets.map((asset, index) => {
                return (
                  <div
                    key={index}
                    className={`ts-infographic-item ${
                      currentActiveGraphic !== index + 1
                        ? 'ts-infographic-item-inactive'
                        : ''
                    }`}
                  >
                    <img
                      className="ts-infographic-item-img"
                      src={asset.icon}
                      alt={asset.icon}
                    />
                    <div>
                      <h2>{asset.step}</h2>
                      <h1 className="ts-infographic-asset-desc">
                        {asset.desc}
                      </h1>
                    </div>
                  </div>
                );
              })}
            </div>
            <WalletSignIn />
          </div>
        </div>
        <div>
          <Row>
            <Col span={12}>
              <h1 className="ts-infographic-title">{FAQ_TEXT}</h1>
            </Col>
            <Col span={12}>
              <div>
                <Collapse
                  ghost
                  defaultActiveKey={DEFAULT_COLLAPSE_ACTIVE_KEY}
                  expandIconPosition="end"
                  expandIcon={(props) => {
                    return props.isActive ? (
                      <img src={FaqCrossIcon} alt={FaqCrossIcon} />
                    ) : (
                      <img src={FaqPlusIcon} alt={FaqPlusIcon} />
                    );
                  }}
                >
                  {data.faqList.map((item, index) => {
                    return (
                      <Panel
                        header={item.question}
                        key={index}
                        className="ts-home-panel"
                      >
                        <p
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        ></p>
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
