import { message } from 'antd';
import {
  BALANCE_FETCH_ERROR,
  FAILED_TO_FETCH_EXTRINSIC_STATUS,
  FAILED_TO_FETCH_IPFS,
  TRANSACTION_FAILED,
} from '../constants/messages';
import { parseVechainBalance } from './dataHelpers';

// get IpfsHash
export const getIpfsHash = async (jurNodeApi) => {
  try {
    let rootInformation = await jurNodeApi?.query?.tokenSwap?.rootInformation();
    if (rootInformation) rootInformation = rootInformation.toHuman();

    return rootInformation?.ipfsPath;
  } catch (e) {
    message.error(FAILED_TO_FETCH_IPFS);
    throw e;
  }
};

const readSubstrateBalance = (balance) => {
  balance = balance.unwrap().toHuman(); // Unwrapping the object
  balance = balance.replace(/,/g, ''); // Cleaning the string; example balance: "100,000,000,000"
  return parseVechainBalance(balance);
};

const getClaimedTokenAmountOnChain = (api, address) => {
  return api.query.tokenSwap
    .latestClaimedBalance(address)
    .then((res) => {
      return res.isNone ? 0 : readSubstrateBalance(res);
    })
    .catch((err) => {
      message.err(BALANCE_FETCH_ERROR);
      throw err;
    });
};

const claimTokens = (api, proof, onSuccess, onFailed) => {
  return api.tx.tokenSwap
    .claim(proof?.signature, proof?.encoded, proof?.storageProof)
    .send()
    .then((res) => {
      if (res)
        return getExtrinsicStatus(api, res.toHuman(), onSuccess, onFailed);
    })
    .catch((err) => {
      message.error(TRANSACTION_FAILED);
      throw err;
    });
};

const getExtrinsicStatus = async (api, extrinsic, onSuccess, onFailed) => {
  try {
    // We're subscribing to new blocks in order to find the block with our extrinsic
    const unsubscribe = await api.rpc.chain.subscribeNewHeads(
      async (header) => {
        const blockHash = await api.rpc.chain.getBlockHash(header.number);
        const block = await api.rpc.chain.getBlock(blockHash);
        const apiAt = await api.at(block.block.header.hash);
        const allRecords = await apiAt.query.system.events();

        block.block.extrinsics.forEach((ext, index) => {
          allRecords
            .filter(
              ({ phase }) =>
                phase.isApplyExtrinsic && phase.asApplyExtrinsic.eq(index)
            )
            .forEach(({ event }) => {
              if (extrinsic === ext.hash.toHex()) {
                unsubscribe();
                if (api.events.system.ExtrinsicSuccess.is(event)) {
                  onSuccess(extrinsic, blockHash.toHex());
                } else if (api.events.system.ExtrinsicFailed.is(event)) {
                  onFailed();
                }
              }
            });
        });
      }
    );
  } catch (e) {
    message.error(FAILED_TO_FETCH_EXTRINSIC_STATUS);
    throw e;
  }
};

export { getClaimedTokenAmountOnChain, claimTokens };
