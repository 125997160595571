export const SET_VECHAIN_ADDRESS = 'SET_VECHAIN_ADDRESS';
export const SET_EMAIL_ADDRESS = 'SET_EMAIL_ADDRESS';
export const SET_LOCKED_TOKENS = 'SET_LOCKED_TOKENS';
export const SET_CLAIMED_TOKENS = 'SET_CLAIMED_TOKENS';
export const SET_JURCHAIN_ADDRESS = 'SET_JURCHAIN_ADDRESS';
export const SET_IS_SUBMITTING = 'SET_IS_SUBMITTING';
export const SET_PROOF = 'SET_PROOF';
export const SET_IPFS_PROOF = 'SET_IPFS_PROOF';
export const SET_SIGNATURE_PROOF = 'SET_SIGNATURE_PROOF';
export const SET_BALANCE_IN_TOKEN_CONTRACT = 'SET_BALANCE_IN_TOKEN_CONTRACT';

export const CLEAR_CONTEXT = 'CLEAR_CONTEXT';
