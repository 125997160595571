import { message } from 'antd';
import { BALANCES_FETCH_ERROR } from '../constants/messages';
import { getClaimedTokenAmountOnChain } from './substrate';
import { getAmountLockedInSwapContract } from './vechain';

export const fetchDepositedAndClaimedAmounts = (address, api) => {
  return Promise.all([
    getAmountLockedInSwapContract(address),
    getClaimedTokenAmountOnChain(api, address),
  ])
    .then(([deposited, claimed]) => {
      return { deposited, claimed };
    })
    .catch((e) => {
      return message.error(BALANCES_FETCH_ERROR);
    });
};
