import Button from '../common/Button';
import React from 'react';
import SuccessGIF from '../assets/Success-Polkadot.gif';
import Card from '../common/Card';
import {
  ALL_TOKENS_ARE_CLAIMED,
  LOCK_TOKENS_FOR_TRANSFER,
  VIEW_TX_ON_JURNODE,
} from '../constants/messages';
import { truncateAddress } from '../helpers/dataHelpers';

function ClaimTokenSuccessRender({
  blockHash,
  claimedTokens,
  vechainBalance,
  onNavigateToLockTokens,
}) {
  return (
    <Card wrapper>
      <div className="ts-claim-token-success-card">
        <img className="ts-response-gif" src={SuccessGIF} alt="Success GIF" />
        <div className="ts-lock-success-title">Success!</div>
        <div className="ts-lock-success-tx-section">
          <p>{VIEW_TX_ON_JURNODE}</p>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_POLKADOT_TX_URL}/${blockHash}/`}
          >
            {truncateAddress(blockHash, 10)}
          </a>
        </div>
        <div className="ts-lock-success-tx-text">
          <p>
            {/* Adding this check to remove this line as claimed tokens might not be available on reload */}
            {claimedTokens ? (
              <>
                You have claimed <span>{claimedTokens}</span> Jur tokens.{' '}
              </>
            ) : null}
            <span>{vechainBalance} tokens</span> remaining on vechain
          </p>
        </div>
        <br />
        {vechainBalance > 0 ? (
          <Button onClick={onNavigateToLockTokens}>
            {LOCK_TOKENS_FOR_TRANSFER}
          </Button>
        ) : (
          ALL_TOKENS_ARE_CLAIMED
        )}
      </div>
    </Card>
  );
}

export default ClaimTokenSuccessRender;
