import { Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CLAIM_TOKENS_FAILURE_ROUTE,
  CLAIM_TOKENS_SUCCESS_ROUTE,
  POLKADOT_SIGNIN_ROUTE,
} from '../constants/routes';
import {
  TokenSwapContext,
  useTokenSwapContextState,
} from '../context/TokenSwapContext';
import { clearLastLockedAt } from '../helpers/dataHelpers';
import { recordClaimAttempt } from '../helpers/googleForm';
import { claimTokens } from '../helpers/substrate';
import { fetchDepositedAndClaimedAmounts } from '../helpers/tokenHelpers';
import { useSubstrateState } from '../substrate-lib';
import ClaimTokensRender from './ClaimTokensRender';

const DEFAULT_UNCLAIMED_AMOUNT = 0;

export default function ClaimTokensHOC() {
  const { setClaimedTokens } = useContext(TokenSwapContext);

  const { vechainAddress, jurAddress, signatureProof } =
    useTokenSwapContextState();

  const { api } = useSubstrateState();
  const navigate = useNavigate();

  const address = vechainAddress;

  const [unclaimedAmount, setUnclaimedAmount] = useState(
    DEFAULT_UNCLAIMED_AMOUNT
  );
  const [isFetchingBalance, setIsFetchingBalance] = useState(false);
  const [isClaimingToken, setIsClaimingToken] = useState(false);

  useEffect(() => {
    if (!signatureProof) navigate(POLKADOT_SIGNIN_ROUTE);
  }, [signatureProof, navigate]);

  useEffect(() => {
    (async () => {
      setIsFetchingBalance(true);
      const { deposited, claimed } = await fetchDepositedAndClaimedAmounts(
        address,
        api
      );

      // Using toFixed to avoid subtraction errors for floats
      setUnclaimedAmount(parseFloat(deposited - claimed).toFixed(2));
      setIsFetchingBalance(false);
    })();
  }, [address, api, navigate]);

  const onSuccess = (extrinsic, blockHash) => {
    setClaimedTokens(unclaimedAmount);
    setIsClaimingToken(false);
    recordClaimAttempt(
      vechainAddress,
      jurAddress,
      unclaimedAmount,
      blockHash,
      extrinsic
    );
    clearLastLockedAt();
    navigate(
      `${CLAIM_TOKENS_SUCCESS_ROUTE}?extrinsic=${extrinsic}&blockHash=${blockHash}`
    );
  };

  const onFailed = () => {
    navigate(CLAIM_TOKENS_FAILURE_ROUTE);
  };

  const onClaimFunds = async () => {
    setIsClaimingToken(true);
    return claimTokens(api, signatureProof, onSuccess, onFailed);
  };

  return (
    <Spin spinning={isFetchingBalance || isClaimingToken}>
      <ClaimTokensRender
        address={address}
        amount={unclaimedAmount}
        polkadotAddress={jurAddress}
        onClaim={onClaimFunds}
      />
    </Spin>
  );
}
