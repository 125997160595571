import { Button as AntButton } from 'antd';
import React from 'react';

function Button({ children, className = 'ts-token-cta', ...props }) {
  return (
    <AntButton block className={className} {...props}>
      {children}
    </AntButton>
  );
}

export default Button;
