import { Input } from 'antd';
import React from 'react';
import VechainLogo from '../assets/vechain-logo.png';
import { Card } from '../common';
import Button from '../common/Button';
import TooltipAddress from '../common/TooltipAddress';
import constants from '../constants/messages';

function LockTokensRender({
  onLockTokens,
  vechainBalance,
  setAmount,
  amount,
  address,
  onInputChange,
  onInputBlur,
}) {
  return (
    <Card wrapper>
      <h2 className="ts-connect-the-wallet">
        Hello, <TooltipAddress address={address} />
      </h2>
      <p className="ts-agree-terms-text">
        {constants.ENTER_THE_AMOUNT_LOCK_TOKENS}
      </p>
      <div className="ts-token-input-wrapper">
        <div className="ts-token-input-row">
          <div className="ts-token-input">
            <Input
              controls={false}
              bordered={false}
              value={amount}
              onChange={onInputChange}
              onBlur={onInputBlur}
            />
          </div>
          <div className="ts-token-chain-logo">
            <img src={VechainLogo} alt="VechainLogo" />
            {constants.JUR_TITLE}
          </div>
        </div>
        <div className="ts-token-info-row">
          <p className="ts-token-available-balance">
            {constants.BALANCE_TEXT} {vechainBalance}
          </p>
          <div
            className="ts-link"
            onClick={() => {
              setAmount(vechainBalance);
            }}
          >
            {constants.MAX_TEXT}
          </div>
        </div>
      </div>
      <Button onClick={onLockTokens} disabled={!Number(amount)}>
        {constants.LOCK_TOKENS_FOR_SWAP}
      </Button>
    </Card>
  );
}

export default LockTokensRender;
