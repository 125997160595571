import { message, Spin } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TX_STATUS_FETCH_ERROR } from '../constants/messages';
import {
  LOCK_TOKENS_ROUTE,
  UPDATING_STATEROOT_ROUTE,
} from '../constants/routes';
import { TokenSwapContext } from '../context/TokenSwapContext';
import { getJurBalance, getTxStatus } from '../helpers/vechain';
import TokenLockTransactionRender from './TokenLockTransactionRender';

const DEFAULT_VECHAIN_BALANCE = 0;
const DEFAULT_FETCH_BALANCE_COUNTER = 0;
const DEFAULT_MAX_COUNT_TO_FETCH_BALANCE = 10;
const DEFAULT_GET_JUR_BALANCE_TIMEOUT_DELAY = 3000;

function TokenLockTransactionHOC() {
  const searchParams = new URLSearchParams(window.location.search);
  const txId = searchParams.get('txid');
  const navigate = useNavigate();
  const { state } = useContext(TokenSwapContext);
  const { lockedTokens, vechainAddress, balanceInTokenContract } = state;

  const [vechainBalance, setVechainBalance] = useState(DEFAULT_VECHAIN_BALANCE);
  const [isFetchingBalance, setIsFetchingBalance] = useState(true);
  const [fetchBalanceCounter, setFetchBalanceCounter] = useState(
    DEFAULT_FETCH_BALANCE_COUNTER
  );

  const handleGetJurBalance = useCallback(() => {
    if (!isFetchingBalance) return;
    getJurBalance(vechainAddress).then((res) => {
      // Checking if balance after locking is not equal to the old balance
      // Also, limiting the number of balance requests using DEFAULT_MAX_COUNT_TO_FETCH_BALANCE
      if (
        !balanceInTokenContract ||
        balanceInTokenContract !== res ||
        fetchBalanceCounter > DEFAULT_MAX_COUNT_TO_FETCH_BALANCE
      ) {
        if (fetchBalanceCounter > DEFAULT_MAX_COUNT_TO_FETCH_BALANCE) {
          getTxStatus(txId).then((res) => {
            if (!res) {
              // Showing error message if can't verify balance deposit or get tx status after 10 tries
              message.error(TX_STATUS_FETCH_ERROR);
              navigate(LOCK_TOKENS_ROUTE);
            }
          });
        } else {
          // need to wait until the newBalance fetch
          setVechainBalance(res);
          setIsFetchingBalance(false);
        }
      }
      setFetchBalanceCounter(fetchBalanceCounter + 1);
    });
  }, [
    txId,
    balanceInTokenContract,
    fetchBalanceCounter,
    isFetchingBalance,
    vechainAddress,
    navigate,
  ]);

  useEffect(() => {
    setTimeout(handleGetJurBalance, DEFAULT_GET_JUR_BALANCE_TIMEOUT_DELAY);
  }, [fetchBalanceCounter, handleGetJurBalance]);

  const navigateToUpdatingStateRoot = () => navigate(UPDATING_STATEROOT_ROUTE);

  return (
    <Spin spinning={isFetchingBalance}>
      {!isFetchingBalance && (
        <TokenLockTransactionRender
          vechainBalance={vechainBalance}
          lockedTokens={lockedTokens}
          txId={txId}
          navigateToUpdatingStateRoot={navigateToUpdatingStateRoot}
        />
      )}
    </Spin>
  );
}

export default TokenLockTransactionHOC;
