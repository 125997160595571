import { Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  APPROVAL_IN_PROGRESS,
  CLAIM_JUR_TOKENS_ON_POLKADOT,
  LOCK_JUR_TOKENS_ON_VECHAIN,
  STEP_ONE,
  STEP_THREE,
  STEP_TWO,
} from '../constants/messages';
import * as routes from '../constants/routes';
import { useTokenSwapContextState } from '../context/TokenSwapContext';

function StepBar() {
  const currentPath = useLocation().pathname;
  const [currentstep, setCurrentStep] = useState(STEP_ONE);
  const { vechainAddress } = useTokenSwapContextState();

  const items = [
    LOCK_JUR_TOKENS_ON_VECHAIN,
    APPROVAL_IN_PROGRESS,
    CLAIM_JUR_TOKENS_ON_POLKADOT,
  ].map((item) => {
    return {
      title: item,
    };
  });

  useEffect(() => {
    if (
      [
        routes.CLAIM_TOKENS_FAILURE_ROUTE,
        routes.CLAIM_TOKENS_ROUTE,
        routes.CLAIM_TOKENS_SUCCESS_ROUTE,
        routes.POLKADOT_SIGNIN_ROUTE,
      ].includes(currentPath)
    )
      setCurrentStep(STEP_THREE);
    else if (currentPath === routes.UPDATING_STATEROOT_ROUTE)
      setCurrentStep(STEP_TWO);
    else setCurrentStep(STEP_ONE);
  }, [currentPath]);

  if (!vechainAddress) return null;

  return (
    <div className="ts-stepbar">
      <Steps
        current={currentstep}
        items={items}
        className="ts-stepbar-steps"
        size="small"
      />
    </div>
  );
}

export default StepBar;
