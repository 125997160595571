import { ConfigProvider } from 'antd';
import React from 'react';
import MobileUI from './components/MobileUI';
import TokenSwapContextProvider from './context/TokenSwapContext';
import { isMobile } from './helpers/uiHelpers';
import { initialiseConnex } from './helpers/vechain';
import Router from './Router';
import './styles/app.scss';
import { SubstrateContextProvider } from './substrate-lib';

export default function App() {
  initialiseConnex();

  if (isMobile()) {
    return <MobileUI />;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter',
        },
      }}
    >
      <TokenSwapContextProvider>
        <SubstrateContextProvider>
          <Router />
        </SubstrateContextProvider>
      </TokenSwapContextProvider>
    </ConfigProvider>
  );
}
