import { Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TokenSwapContext } from '../context/TokenSwapContext';
import { getJurBalance } from '../helpers/vechain';
import ClaimTokenSuccessRender from './ClaimTokenSuccessRender';
import { LOCK_TOKENS_ROUTE } from '../constants/routes';

function ClaimTokenSuccessHOC() {
  const context = useContext(TokenSwapContext);
  const { vechainAddress, claimedTokens } = context.state;
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);
  const searchParams = new URLSearchParams(window.location.search);
  const blockHash = searchParams.get('blockHash');
  const [isFetchingBalance, setIsFetchingBalance] = useState(false);

  useEffect(() => {
    setIsFetchingBalance(true);
    getJurBalance(vechainAddress).then((res) => {
      setBalance(res);
      setIsFetchingBalance(false);
    });
  }, [vechainAddress]);

  const onNavigateToLockTokens = () => {
    navigate(LOCK_TOKENS_ROUTE);
  };

  return (
    <Spin spinning={isFetchingBalance}>
      <ClaimTokenSuccessRender
        claimedTokens={claimedTokens}
        vechainBalance={balance}
        blockHash={blockHash}
        onNavigateToLockTokens={onNavigateToLockTokens}
      />
    </Spin>
  );
}

export default ClaimTokenSuccessHOC;
