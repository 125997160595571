// GENERAL
export const JUR_TITLE = 'JUR';
export const TOKEN_SWAP_TITLE = 'Token Swap';
export const CLAIM_TOKENS_TEXT = 'Claim Token';
export const ADDRESS_IS_TEXT = 'Address:';
export const SYNC2_WALLET_TITLE = 'Sync 2.0';
export const JUR_TERMS_TITLE = 'Jur’s Terms and Conditions';
export const PRIVACY_POLICY_TITLE = 'Privacy Policy';
export const NEED_HELP_TEXT = 'Need help';
export const GET_READY_FOR_POLKADOT_TEXT = 'Get Ready For Substrate';
export const POLKADOT_ADDRESS = 'Wallet Address';
export const STAY_IN_TOUCH = 'Stay In Touch';
export const CLICK_HERE_TEXT = 'Click Here';
export const CONFIRM_AND_SIGN = 'Confirm & Sign';
export const NEXT_STEP_TEXT = 'Next Step';
export const CONTACT_JUR_TEXT = 'Contact';
export const SUCCESS_TEXT = 'Success!';
export const TOKENS_TEXT = 'tokens';
export const BALANCE_TEXT = 'Balance:';
export const MAX_TEXT = 'Max';
export const AGREEMENT = 'agreement';
export const IDENTIFICATION = 'identification';
export const ACCEPT_AND_AGREEING_TO_TEXT = 'By accepting, you are agreeing to';
export const TERMS_AND_CONDITIONS = 'terms and conditions';
export const FOLLOW_US_ON = 'Follow us on';
export const TWITTER_TEXT = 'Twitter';
export const DISCORD_TEXT = 'Discord';
export const YOUTUBE_TEXT = 'Youtube';
export const VIEW_ON_VECHAIN = 'View on VeChain';
export const FAQ_TEXT = 'FAQ';
export const JUR_MOVING_TO_DOT_TEXT = 'JUR token is moving to Substrate';
export const LOCK_TOKENS_TO_SWAP =
  'Lock your $JUR on Vechain to swap them for new $JUR on Substrate.';
export const FOOTER_TEXT = 'Copyright ©2023 Product by Jur AG';

// CONFIG/LOGIN
export const SUBSTRATE_NODE_ERROR =
  'Substrate node failed to establish a connection. Please check your network settings and try again.';
export const LOGIN_SIGNATURE_FAILURE = 'Please sign to login!';
export const SIGN_TO_PROCEED = 'Please sign to proceed!';
export const SIGNING_INTO_TOKENSWAP =
  'You are signing in on Jur Token Swap app';
export const JUR_ADDRESS_CONFIRMATION_TEXT = 'My JUR address is';

// CONTRACT STATES
export const LOCK_SIGNATURE_SUCCESS = 'Transaction has been signed!';
export const LOCK_SIGNATURE_FAILURE =
  'Please sign the transaction to lock the tokens and proceed';

// FETCHING ERRORS
export const TX_STATUS_FETCH_ERROR =
  'Failed to verify Transaction Status. Please ensure you have enough VTHO to complete the transaction.';
export const BALANCE_FETCH_ERROR = 'Failed to fetch Jur Balance';
export const ADDRESS_MISSING = 'Address Missing';
export const BALANCES_FETCH_ERROR = 'Failed to fetch Balances!';

// VALIDATION ERRORS
export const INVALID_POLKADOT_ADDRESS = 'Please enter a valid address!';
export const INVALID_TOKEN_AMOUNT_TO_LOCK =
  'You cannot enter an amount more than the available balance in your wallet';
export const INVALID_VECHAIN_ADDRESS_WHILE_SIGNING =
  'Please sign with the VeChain wallet you logged in!';

// PROOF & IPFS STATE
export const NO_PROOF_ERROR = 'No Proof found in stateRoot!';
export const PROOF_YET_TO_BE_UPDATED =
  'Please note the timestamp of the last state root update, and check back after it has been updated.';
export const FAILED_TO_FETCH_PROOFS = 'Failed to fetch proofs!';
export const FAILED_TO_FETCH_IPFS = 'Failed to fetch ipfsHash!';

// POLKADOT NOTIFICATIONS
export const READY_TO_BE_CLAIMED =
  'Your $JUR has been transferred from VeChain and is now ready to be claimed on Substrate.';
export const TAKING_LONGER_THAN_EXPECTED =
  'YOUR TRANSFER IS TAKING LONGER THAN EXPECTED';

// TRANSACTION ERRORS
export const FAILED_TO_FETCH_EXTRINSIC_STATUS =
  'Failed to fetch extrinsic error!';
export const TRANSACTION_FAILED =
  "Transaction Failed! Please wait till the state root has been updated after you've locked your tokens. If the problem still persists, Please contact support.";
export const TRANSACTION_REVERTED = 'Transaction reverted!';

// VECHAIN WALLET SIGN IN
export const CONNECT_TO_VECHAIN_TEXT =
  'Please connect your VeChain Sync 2.0 wallet to swap your tokens to Substrate';
export const CONNECT_TO_SYNC_WALLET_TITLE = 'Connect a wallet';
export const CONNECTING_YOUR_SYNC_WALLET_TEXT =
  'By connecting your wallet, you agree to';
export const SIGNING_UP_FOR_SYNC2 = 'signing up for Sync 2.0?';

// NAVBAR
export const COPY_ADDRESS_TEXT = 'Copy address';
export const DISCONNECT_TEXT = 'Disconnect';
export const SUBMIT_TEXT = 'SUBMIT';

// EMAIL FORM
export const EMAIL_FORM_TITLE = 'Email';
export const SKIP_THIS_STEP = 'Skip this step';
export const EMAIL_FORM_DESC =
  'Please provide your email so that Jur can notify you once your token is ready to claim on Substrate. Alternatively, you can follow our Twitter feed for the announcement.';
export const ENTER_VALID_EMAIL = 'Please enter a valid Email!';
export const EMAIL_STORED_SUCCESSFULLY = 'Email Stored Successfully';
export const EMAIL_INPUT_PLACEHOLDER = 'enter email address (optional)';

// UPDATING STATE-ROOT
export const TRANSFER_IN_PROGRESS = 'Transfer In Progress';
export const CHECK_BACK_LATER =
  'Please check back later to claim your tokens after the completion of the transfer to Substrate.';
export const JUR_APPROVES_STATEROOT_TEXT =
  'Jur manually approves state roots of VeChain transactions. You will be able to claim your tokens after you see the date of the state root update has changed.';
export const HAVE_ANY_QUERIES_TEXT = 'Jur if you have any questions.';

// TOKEN LOCK TRANSACTION
export const TX_HASH_TEXT = 'View transaction on Vechain';
export const PROVIDE_EMAIL_TEXT =
  'Please check back later to claim your tokens after the completion of the transfer to Substrate.\n\nJur manually approves state roots of Vechain transactions.';
export const TOKENS_ARE_LOCKED_TEXT = 'are locked and on the way to Substrate.';
export const REMAINING_ON_VECHAIN = 'remaining on VeChain.';

// LOCK TOKENS
export const ENTER_THE_AMOUNT_LOCK_TOKENS =
  'Please enter the amount to lock your tokens in Jur contract and later transfer it to Substrate.';
export const LOCK_TOKENS_FOR_SWAP = 'Lock Tokens For Swap';
export const LOCK_TOKENS_FOR_TRANSFER = 'Lock Tokens For Transfer';

// POLKADOT ADDRESS SIGNATURE PAGE
export const LETS_GET_DOT_WALLET_READY =
  "Let's Get Your Substrate Wallet Ready";
export const ENTER_ADDRESS_TO_CONTINUE = 'Your tokens are ready to claim.';
export const DONT_HAVE_DOT_WALLET = 'Don’t have a Substrate wallet?';
export const GET_ONE_HERE = 'Get one here';

// CLAIM TOKENS
export const LOCK_TO_CONTINUE =
  'No tokens available to be claimed. Please lock tokens to continue.';
export const CONGRATULATIONS_USER = 'Congratulations,';
export const VIEW_TX_ON_JURNODE = 'View transaction on Jur Node';
export const ALL_TOKENS_ARE_CLAIMED = 'All of your tokens are now claimed!';

// steps
export const LOCK_JUR_TOKENS_ON_VECHAIN = 'Lock JUR tokens on VeChain';
export const APPROVAL_IN_PROGRESS = 'Approval in progress';
export const CLAIM_JUR_TOKENS_ON_POLKADOT = 'Claim JUR tokens on Substrate';
export const LOCK_EXISTING_JUR_TOKENS_ON_VECHAIN =
  'Lock your existing Jur tokens on Vechain';
export const WAIT_FOR_APPROVAL = 'Wait for approval';
export const CLAIM_NEW_JUR_TOKENS_ON_POLKADOT =
  'Claim new Jur tokens on Substrate';
export const STEP_ONE = 0;
export const STEP_TWO = 1;
export const STEP_THREE = 2;
export const STEP_ONE_TEXT = 'Step 1';
export const STEP_TWO_TEXT = 'Step 2';
export const STEP_THREE_TEXT = 'Step 3';

const constants = {
  STEP_ONE,
  STEP_TWO,
  STEP_THREE,
  STEP_ONE_TEXT,
  STEP_TWO_TEXT,
  STEP_THREE_TEXT,
  LOCK_JUR_TOKENS_ON_VECHAIN,
  LOCK_EXISTING_JUR_TOKENS_ON_VECHAIN,
  APPROVAL_IN_PROGRESS,
  WAIT_FOR_APPROVAL,
  CLAIM_JUR_TOKENS_ON_POLKADOT,
  CLAIM_NEW_JUR_TOKENS_ON_POLKADOT,
  JUR_TITLE,
  CONNECT_TO_VECHAIN_TEXT,
  CONNECT_TO_SYNC_WALLET_TITLE,
  CONNECTING_YOUR_SYNC_WALLET_TEXT,
  SIGNING_UP_FOR_SYNC2,
  TOKEN_SWAP_TITLE,
  SYNC2_WALLET_TITLE,
  JUR_TERMS_TITLE,
  PRIVACY_POLICY_TITLE,
  NEED_HELP_TEXT,
  TRANSFER_IN_PROGRESS,
  CLICK_HERE_TEXT,
  GET_READY_FOR_POLKADOT_TEXT,
  STAY_IN_TOUCH,
  EMAIL_FORM_TITLE,
  EMAIL_FORM_DESC,
  NEXT_STEP_TEXT,
  CHECK_BACK_LATER,
  CONTACT_JUR_TEXT,
  HAVE_ANY_QUERIES_TEXT,
  COPY_ADDRESS_TEXT,
  DISCONNECT_TEXT,
  TX_HASH_TEXT,
  PROVIDE_EMAIL_TEXT,
  TOKENS_ARE_LOCKED_TEXT,
  REMAINING_ON_VECHAIN,
  SUCCESS_TEXT,
  TOKENS_TEXT,
  BALANCE_TEXT,
  MAX_TEXT,
  ENTER_THE_AMOUNT_LOCK_TOKENS,
  LOCK_TOKENS_FOR_SWAP,
  SKIP_THIS_STEP,
  JUR_APPROVES_STATEROOT_TEXT,
  AGREEMENT,
  IDENTIFICATION,
  TWITTER_TEXT,
  DISCORD_TEXT,
  YOUTUBE_TEXT,
  FOLLOW_US_ON,
  SUBMIT_TEXT,
  VIEW_ON_VECHAIN,
  ACCEPT_AND_AGREEING_TO_TEXT,
  TERMS_AND_CONDITIONS,
  ENTER_VALID_EMAIL,
  EMAIL_INPUT_PLACEHOLDER,
  EMAIL_STORED_SUCCESSFULLY,
  FOOTER_TEXT,
  POLKADOT_ADDRESS,
  CONFIRM_AND_SIGN,
};
export default constants;
