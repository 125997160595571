import { Popover } from 'antd';
import React, { useContext, useState } from 'react';
import Button from '../common/Button';

import { useNavigate } from 'react-router-dom';
import CopyIcon from '../assets/copy.svg';
import DropdownIcon from '../assets/dropdown.svg';
import Logo from '../assets/logo.svg';
import OpenInNewTab from '../assets/open-in-new-tab.svg';
import SyncIcon from '../assets/sync-navbar.svg';
import EmailForm from '../components/EmailForm';
import constants from '../constants/messages';
import {
  COMPANY_DISCORD_URL,
  COMPANY_TWITTER_URL,
  COMPANY_YOUTUBE_URL,
} from '../constants/redirectUrls';
import { TokenSwapContext } from '../context/TokenSwapContext';
import { truncateAddress } from '../helpers/dataHelpers';
import { handleCopyAddress } from '../helpers/formHelpers';

function NavBar() {
  const context = useContext(TokenSwapContext);
  const vechainAddress = context.state.vechainAddress;
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    context.clearContext();
    navigate('/');
  };

  const _handleCopyAddress = () => handleCopyAddress(vechainAddress);

  const MenuAction = ({ text, icon, onClick }) => {
    return (
      <div className="ts-navbar-copy-address" onClick={onClick}>
        <p className="ts-navbar-copy-address-text">{text}</p>
        <img src={icon} alt={icon} />
      </div>
    );
  };

  const openTwitter = () => window.open(COMPANY_TWITTER_URL);
  const openDiscord = () => window.open(COMPANY_DISCORD_URL);
  const openYoutube = () => window.open(COMPANY_YOUTUBE_URL);
  const openAccountOnVechain = () =>
    window.open(
      `${process.env.REACT_APP_ACCOUNT_OVERVIEW_ON_VECHAIN_URL}/${vechainAddress}`
    );

  const content = (
    <div className="ts-navbar-popover-content">
      <div className="ts-navbar-web3-section">
        <MenuAction
          text={constants.COPY_ADDRESS_TEXT}
          icon={CopyIcon}
          onClick={_handleCopyAddress}
        />
        <MenuAction
          text={constants.VIEW_ON_VECHAIN}
          icon={OpenInNewTab}
          onClick={openAccountOnVechain}
        />

        <div className="ts-navbar-disconnect">
          <Button className="ts-navbar-disconnect-btn" onClick={handleLogout}>
            {constants.DISCONNECT_TEXT}
          </Button>
        </div>
      </div>
      <div className="ts-navbar-user-section">
        {isPopoverOpen && <EmailForm />}
        <div>
          <h2 className="ts-navbar-follow-us-on">{constants.FOLLOW_US_ON}</h2>
          <MenuAction
            text={constants.TWITTER_TEXT}
            icon={OpenInNewTab}
            onClick={openTwitter}
          />
          <MenuAction
            text={constants.DISCORD_TEXT}
            icon={OpenInNewTab}
            onClick={openDiscord}
          />
          <MenuAction
            text={constants.YOUTUBE_TEXT}
            icon={OpenInNewTab}
            onClick={openYoutube}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="ts-navbar">
      <a href="/">
        <img src={Logo} alt="Logo" />
      </a>
      <div>
        {vechainAddress ? (
          <Popover
            onOpenChange={(isOpen) => setIsPopoverOpen(isOpen)}
            content={content}
            overlayClassName="ts-navbar-popover"
            title={
              <div className="ts-navbar-popover-address">
                {truncateAddress(vechainAddress, window.innerWidth / 120)}
              </div>
            }
            trigger="hover"
            placement="bottomRight"
          >
            <Button size="large" className="ts-navbar-btn">
              <img src={SyncIcon} alt="SyncIcon" className="ts-navbar-icon" />
              {truncateAddress(vechainAddress, 4)}
              <img
                src={DropdownIcon}
                alt="DropdownIcon"
                className="ts-navbar-dropdown-icon"
              />
            </Button>
          </Popover>
        ) : null}
      </div>
    </div>
  );
}

export default NavBar;
