import { Spin } from 'antd';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { useTokenSwapContextState } from './context/TokenSwapContext';
import React from 'react';
import LoaderWrapper from './common/LoaderWrapper';
import * as routes from './constants/routes';
import {
  ClaimTokenFailed,
  ClaimTokens,
  ClaimTokenSuccess,
  InProgress,
  LockTokens,
  PolkadotAddressFormHOC,
  TokenLockTransaction,
  StepBar,
  Home,
  NavBar,
} from './pages';

function Router() {
  const { isSubmitting, vechainAddress } = useTokenSwapContextState();

  return (
    <div className="ts-main-wrapper">
      <LoaderWrapper>
        <Spin spinning={isSubmitting}>
          {vechainAddress ? (
            <RouterProvider router={router} />
          ) : (
            <RouterProvider router={loggedOutRouter} />
          )}
        </Spin>
      </LoaderWrapper>
    </div>
  );
}

export default Router;

const loggedInRouterChildren = [
  {
    path: '*',
    element: <Navigate to="/" />,
  },
  {
    path: '/',
    element: <Navigate to={routes.LOCK_TOKENS_ROUTE} />,
  },
  {
    path: routes.UPDATING_STATEROOT_ROUTE,
    element: <InProgress />,
  },
  {
    path: routes.POLKADOT_SIGNIN_ROUTE,
    element: <PolkadotAddressFormHOC />,
  },
  {
    path: routes.LOCK_TOKENS_ROUTE,
    element: <LockTokens />,
  },
  {
    path: routes.LOCK_TOKENS_SUCCESS_ROUTE,
    element: <TokenLockTransaction />,
  },
  {
    path: routes.CLAIM_TOKENS_ROUTE,
    element: <ClaimTokens />,
  },
  {
    path: routes.CLAIM_TOKENS_SUCCESS_ROUTE,
    element: <ClaimTokenSuccess />,
  },
  {
    path: routes.CLAIM_TOKENS_FAILURE_ROUTE,
    element: <ClaimTokenFailed />,
  },
];

function BodyWrapper() {
  return (
    <div className="ts-body-wrapper">
      <NavBar />
      <StepBar />
      <Outlet />
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <BodyWrapper />,
    children: loggedInRouterChildren,
  },
]);

const loggedOutRouter = createBrowserRouter([
  {
    path: '*',
    element: <Navigate to="/" />,
  },
  {
    path: '/',
    element: <Home />,
  },
]);
