import Connex from '@vechain/connex';
import { message } from 'antd';
import { Certificate } from 'thor-devkit';
import {
  VECHAIN_APPROVE_METHOD,
  VECHAIN_BALANCE_OF_METHOD,
  VECHAIN_DEPOSIT_METHOD,
  VECHAIN_TOTAL_AMOUNT_LOCKED,
} from '../constants/contractMethods';
import {
  ADDRESS_MISSING,
  AGREEMENT,
  BALANCE_FETCH_ERROR,
  IDENTIFICATION,
  INVALID_VECHAIN_ADDRESS_WHILE_SIGNING,
  JUR_ADDRESS_CONFIRMATION_TEXT,
  LOCK_SIGNATURE_FAILURE,
  LOCK_SIGNATURE_SUCCESS,
  LOGIN_SIGNATURE_FAILURE,
  SIGNING_INTO_TOKENSWAP,
  SIGN_TO_PROCEED,
  TRANSACTION_REVERTED,
  TX_STATUS_FETCH_ERROR,
} from '../constants/messages';
import ERC20 from '../contracts/vechain/ERC20.json';
import TokenSwap from '../contracts/vechain/TokenSwap.json';
import { fetchVechainAddress } from './authHelpers';
import {
  humanToVechain,
  parseVechainBalance,
  roundAmount,
} from './dataHelpers';

let connex = {};
let vendor;

const addresses = {
  erc20Address: process.env.REACT_APP_VECHAIN_JUR_TOKEN_CONTRACT_ADDRESS,
  tokenSwapAddress: process.env.REACT_APP_VECHAIN_TOKEN_SWAP_CONTRACT_ADDRESS,
};

export const TokenContract = {};
export const SwapContract = {};

const handleSyncSignVechainAddress = async () => {
  try {
    const txMessage = {
      purpose: IDENTIFICATION,
      payload: {
        type: 'text',
        content: SIGNING_INTO_TOKENSWAP,
      },
    };
    let { annex, signature } = await vendor.sign('cert', txMessage).request();

    return { signer: annex.signer, signature };
  } catch (e) {
    message.error(LOGIN_SIGNATURE_FAILURE);
  }
};

const handleSyncSignJurAddress = async (address = null, onFailed) => {
  try {
    const txMessage = {
      purpose: AGREEMENT,
      payload: {
        type: 'text',
        content: `${JUR_ADDRESS_CONFIRMATION_TEXT} ${address}`,
      },
    };
    let { annex, signature } = await vendor.sign('cert', txMessage).request();

    if (!checkAndValidateSigner(annex.signer)) {
      message.error(INVALID_VECHAIN_ADDRESS_WHILE_SIGNING);
      onFailed();
      return;
    }

    const encoded = Certificate.encode({
      ...txMessage,
      ...annex,
    });
    return { signer: annex.signer, encoded, signature };
  } catch (e) {
    message.error(SIGN_TO_PROCEED);
    onFailed();
  }
};

const initialiseConnex = () => {
  connex = new Connex({
    node: process.env.REACT_APP_VECHAIN_NETWORK_URL, // veblocks public node, use your own if needed
    network:
      process.env.REACT_APP_ENV === 'local'
        ? {
            id: process.env.REACT_APP_VECHAIN_NETWORK_ID,
          }
        : process.env.REACT_APP_VECHAIN_NETWORK, // defaults to mainnet, so it can be omitted here
  });

  vendor = new Connex.Vendor(process.env.REACT_APP_VECHAIN_NETWORK);

  // Initialise Token Contract
  const balanceOf = ERC20.abi.find((x) => x.name === VECHAIN_BALANCE_OF_METHOD);
  TokenContract[balanceOf.name] = defineConstant(
    balanceOf,
    addresses.erc20Address
  );

  // Initialise Swap Contract
  const totalAmountLocked = TokenSwap.abi.find(
    (x) => x.name === VECHAIN_TOTAL_AMOUNT_LOCKED
  );
  SwapContract['totalAmountLocked'] = defineConstant(
    totalAmountLocked,
    addresses.tokenSwapAddress
  );
};

const getJurBalance = (address) => {
  if (!address) throw new Error(ADDRESS_MISSING);
  return TokenContract.balanceOf(address)
    .then((res) => {
      if (res?.reverted) {
        message.error(TRANSACTION_REVERTED);
      }
      const balance = parseVechainBalance(res?.decoded[0]);
      const roundedBalance = roundAmount({ amount: balance });
      return roundedBalance;
    })
    .catch((err) => {
      message.error(BALANCE_FETCH_ERROR);
    });
};

const lockTokens = (amount, onSuccess, onFailed) => {
  amount = humanToVechain(amount);
  const approveABI = ERC20.abi.find((n) => n.name === VECHAIN_APPROVE_METHOD);
  const depositABI = TokenSwap.abi.find(
    (n) => n.name === VECHAIN_DEPOSIT_METHOD
  );

  const approvalClause = connex.thor
    .account(addresses.erc20Address)
    .method(approveABI)
    .asClause(addresses.tokenSwapAddress, amount);
  const depositClause = connex.thor
    .account(addresses.tokenSwapAddress)
    .method(depositABI)
    .asClause(amount);

  connex.vendor
    .sign('tx', [approvalClause, depositClause])
    .request()
    .then((res) => {
      if (!checkAndValidateSigner(res?.signer)) {
        message.error(INVALID_VECHAIN_ADDRESS_WHILE_SIGNING);
        onFailed();
        return;
      }

      message.success(LOCK_SIGNATURE_SUCCESS);
      onSuccess(res.txid);
    })
    .catch((err) => {
      message.error(LOCK_SIGNATURE_FAILURE);
      onFailed();
    });
};

const getAmountLockedInSwapContract = (address) => {
  if (!address) throw new Error(ADDRESS_MISSING);
  return SwapContract.totalAmountLocked(address)
    .then((res) => {
      if (res?.reverted) {
        message.error(TRANSACTION_REVERTED);
      }
      return parseVechainBalance(res?.decoded[0]);
    })
    .catch((err) => {
      message.error(BALANCE_FETCH_ERROR);
    });
};

function defineConstant(method, contractAddress) {
  return (...args) =>
    connex.thor
      .account(contractAddress)
      .method(method)
      .call(...args);
}

export const getTxStatus = (txid) => {
  try {
    return connex.thor
      .transaction(txid)
      .get()
      .then((res) => res);
  } catch (e) {
    message.error(TX_STATUS_FETCH_ERROR);
  }
};

const checkAndValidateSigner = (signer) => {
  const storedVeChainAddress = fetchVechainAddress();
  return signer === storedVeChainAddress;
};

const getBlockTimestamp = async (blockNo) => {
  const block = await connex.thor.block(blockNo);
  return block.get().then((blockDetails) => {
    return blockDetails.timestamp;
  });
};

export {
  connex,
  vendor,
  getJurBalance,
  lockTokens,
  handleSyncSignVechainAddress,
  handleSyncSignJurAddress,
  initialiseConnex,
  getAmountLockedInSwapContract,
  getBlockTimestamp,
};
