import GoogleForm from 'google-form-send';
import { fetchEmail } from './formHelpers';

// FORM URLS
const lockFormURL = process.env.REACT_APP_LOCK_GFORM_URL;
const claimFormURL = process.env.REACT_APP_CLAIM_GFORM_URL;

// FIELD CONSTANTS
const emailFieldId = 'entry.1098723217';
const lockVetAddressFieldId = 'entry.1317595459';
const lockAmountFieldId = 'entry.1404916694';
const lockTxFieldId = 'entry.352674313';
const claimVetAddressFieldId = 'entry.1317595459';
const polkadotAddressFieldId = 'entry.175699745';
const claimAmountFieldId = 'entry.1404916694';
const blockHashFieldId = 'entry.352674313';
const extrinsicFieldId = 'entry.1251916295';

export const recordLockAttempt = (address, amount, txId) => {
  try {
    const email = fetchEmail();
    const form = new GoogleForm(lockFormURL);
    form.addField(emailFieldId, email);
    form.addField(lockVetAddressFieldId, address);
    form.addField(lockAmountFieldId, amount);
    form.addField(lockTxFieldId, txId);
    form.send();
  } catch (e) {
    handleGoogleFormSubmitError(e);
  }
};

export const recordClaimAttempt = (
  vechainAddress,
  polkadotAddress,
  amount,
  blockHash,
  extrinsicHash
) => {
  try {
    const form = new GoogleForm(claimFormURL);
    form.addField(claimVetAddressFieldId, vechainAddress);
    form.addField(polkadotAddressFieldId, polkadotAddress);
    form.addField(claimAmountFieldId, amount);
    form.addField(blockHashFieldId, blockHash);
    form.addField(extrinsicFieldId, extrinsicHash);
    form.send();
  } catch (e) {
    handleGoogleFormSubmitError(e);
  }
};

const handleGoogleFormSubmitError = (err) => {
  // TODO: Add Error Handling
};
