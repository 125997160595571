import React, { useEffect, useState } from 'react';
import InProgressGif from '../assets/Visual-Tprogress.gif';
import { Card } from '../common';
import constants from '../constants/messages';
import { loadProofDB } from '../helpers/ipfs';
import { getIpfsHash } from '../helpers/substrate';
import { getBlockTimestamp } from '../helpers/vechain';
import { useSubstrateState } from '../substrate-lib';

function InProgress() {
  const [lastStateRootUpdate, setLastStateRootUpdate] = useState('');
  const { api, apiState } = useSubstrateState();

  useEffect(() => {
    (async function () {
      const UNIX_MULTIPLIER = 1000;
      if (api) {
        const ipfsHash = await getIpfsHash(api);

        if (!ipfsHash) return;
        const proofDB = await loadProofDB(ipfsHash);

        if (proofDB.revision) {
          getBlockTimestamp(proofDB.revision).then((res) => {
            const blockTimestamp = res;
            const formattedTimestamp = blockTimestamp * UNIX_MULTIPLIER;
            const formattedDate = new Date(formattedTimestamp);
            setLastStateRootUpdate(formattedDate.toString());
          });
        }
      }
    })();
  }, [api, apiState]);

  return (
    <Card wrapper className="ts-inprogress-card">
      <img
        src={InProgressGif}
        alt="Gif while stateroot is updating"
        className="ts-in-progress"
      />
      <h2 className="ts-transfer-progress-text">
        {constants.TRANSFER_IN_PROGRESS}
      </h2>
      <p className="ts-check-later-text">
        {constants.CHECK_BACK_LATER}
        <br /> <br />
        {constants.JUR_APPROVES_STATEROOT_TEXT}
      </p>
      <br />
      <p className="ts-need-help-text">
        Last State Root Update: {lastStateRootUpdate}
      </p>
      <br />
      <p className="ts-need-help-text">
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
        >
          {constants.CONTACT_JUR_TEXT}
        </a>
        {` ${constants.HAVE_ANY_QUERIES_TEXT}`}
      </p>
    </Card>
  );
}

export default InProgress;
