import Button from '../common/Button';
import React from 'react';
import DotLogo from '../assets/polkadot-logo.png';
import { Card } from '../common';
import TooltipAddress from '../common/TooltipAddress';
import {
  CONGRATULATIONS_USER,
  READY_TO_BE_CLAIMED,
  CLAIM_TOKENS_TEXT,
  JUR_TITLE,
  ADDRESS_IS_TEXT,
} from '../constants/messages';

export default function ClaimTokensRender({
  address,
  polkadotAddress,
  onClaim,
  amount,
}) {
  return (
    <Card wrapper>
      <h2 className="ts-connect-the-wallet">
        {CONGRATULATIONS_USER} <TooltipAddress address={address} />
      </h2>
      <p className="ts-agree-terms-text">{READY_TO_BE_CLAIMED}</p>
      <div className="ts-token-input-wrapper">
        <div className="ts-token-input-row">
          <div className="ts-token-input">{amount}</div>
          <div className="ts-token-chain-logo">
            <img src={DotLogo} alt="VechainLogo" />
            {JUR_TITLE}
          </div>
        </div>
      </div>
      <div className="ts-token-polkadot-address ">
        {`${ADDRESS_IS_TEXT} `} <TooltipAddress address={polkadotAddress} />
      </div>
      <Button onClick={onClaim}>{CLAIM_TOKENS_TEXT}s</Button>
    </Card>
  );
}
