import { message } from 'antd';
import {
  PROOF_YET_TO_BE_UPDATED,
  FAILED_TO_FETCH_PROOFS,
} from '../constants/messages';

// get the whole proof data from the ipfs endpoint
export const loadProofDB = async (ipfsProofHash) => {
  try {
    const url = `${process.env.REACT_APP_IPFS_ENDPOINT_URL}/ipfs/${ipfsProofHash}`;
    const response = await fetch(url, { cache: 'no-store' });
    const proofdb = await response.json();

    return proofdb;
  } catch (e) {
    message.error(FAILED_TO_FETCH_PROOFS);
  }
};

// check and get the signer proof
export const getSignerProof = async (proofdb, signer) => {
  if (!proofdb.proofs[signer]) message.error(PROOF_YET_TO_BE_UPDATED);
  return proofdb.proofs[signer];
};
