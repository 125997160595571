import { Checkbox, Input, message } from 'antd';
import React, { useState } from 'react';
import Button from '../common/Button';

import constants from '../constants/messages';
import { fetchEmail, storeEmail, validateEmail } from '../helpers/formHelpers';

function EmailForm() {
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState('');

  const handleSubmit = async () => {
    if (validateEmail(email)) {
      storeEmail(email);
      await message.success(constants.EMAIL_STORED_SUCCESSFULLY);
    } else {
      await message.error(constants.ENTER_VALID_EMAIL);
    }
  };

  const EmailInput = (
    <Input
      className="ts-email-input"
      onChange={(e) => setEmail(e.target.value)}
      placeholder={constants.EMAIL_INPUT_PLACEHOLDER}
    />
  );

  const SubmitButton = (
    <Button
      disabled={!email || !checked}
      className="ts-navbar-disconnect-btn"
      onClick={handleSubmit}
    >
      {constants.SUBMIT_TEXT}
    </Button>
  );

  return !fetchEmail() ? (
    <>
      <h1 className="ts-email-label">{constants.EMAIL_FORM_TITLE}</h1>
      {EmailInput}

      <div>
        <h2 className="ts-navbar-terms-conditions ts-email-desc">
          <Checkbox
            onChange={(e) => setChecked(e.target.checked)}
            className="ts-navbar-checkbox"
          />
          <div className="ts-checkbox-text">
            {`${constants.ACCEPT_AND_AGREEING_TO_TEXT} `}
            <a
              target="_blank"
              rel="noreferrer"
              href={process.env.REACT_APP_JUR_TERMS_LINK}
            >
              {constants.TERMS_AND_CONDITIONS}
            </a>
          </div>
        </h2>

        {SubmitButton}
      </div>
    </>
  ) : null;
}

export default EmailForm;
