import { Tooltip } from 'antd';
import React from 'react';
import InfoIcon from '../assets/Vector.png';
import { truncateAddress } from '../helpers/dataHelpers';

function TooltipAddress({ address }) {
  return (
    <>
      {truncateAddress(address)}
      <Tooltip title={address}>
        <img className="ts-address-tooltip" src={InfoIcon} alt="InfoIcon" />
      </Tooltip>
    </>
  );
}

export default TooltipAddress;
