import { message, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { INVALID_TOKEN_AMOUNT_TO_LOCK } from '../constants/messages';
import {
  LOCK_TOKENS_SUCCESS_ROUTE,
  POLKADOT_SIGNIN_ROUTE,
} from '../constants/routes';
import { TokenSwapContext } from '../context/TokenSwapContext';
import { setLastLockedAt } from '../helpers/dataHelpers';
import {
  handleNumberInputBlur,
  handleNumberInputChange,
} from '../helpers/formHelpers';
import { recordLockAttempt } from '../helpers/googleForm';
import { fetchDepositedAndClaimedAmounts } from '../helpers/tokenHelpers';
import { getJurBalance, lockTokens } from '../helpers/vechain';
import { useSubstrateState } from '../substrate-lib';
import LockTokensRender from './LockTokensRender';

const DEFAULT_AMOUNT = 0;

function LockTokensHOC() {
  const { api } = useSubstrateState();
  const { state, setLockedTokens, setIsSubmitting, setBalanceInTokenContract } =
    useContext(TokenSwapContext);
  const { balanceInTokenContract, vechainAddress: address } = state;
  const [isFetchingBalance, setIsFetchingBalance] = useState(false);
  const [amount, setAmount] = useState(DEFAULT_AMOUNT);
  const navigate = useNavigate();

  // we are fetching the total deposited and total claimed tokens from the respective abis
  // and calculating the no of unClaimed tokens
  useEffect(() => {
    (async () => {
      const { deposited, claimed } = await fetchDepositedAndClaimedAmounts(
        address,
        api
      );

      if (deposited > claimed) navigate(POLKADOT_SIGNIN_ROUTE);
    })();
  }, [address, api, navigate]);

  useEffect(() => {
    if (address && !balanceInTokenContract) {
      setIsFetchingBalance(true);
      getJurBalance(address).then((res) => {
        setIsFetchingBalance(false);
        setBalanceInTokenContract(res);
      });
    }
  }, [address, balanceInTokenContract, setBalanceInTokenContract]);

  const onLockSuccess = (txId) => {
    setLockedTokens(amount);
    recordLockAttempt(address, amount, txId);
    setLastLockedAt();
    navigate(`${LOCK_TOKENS_SUCCESS_ROUTE}?txid=${txId}`);
    setIsSubmitting(false);
  };

  const onLockFailed = () => setIsSubmitting(false);

  const handleLockTokens = () => {
    setIsSubmitting(true);

    lockTokens(amount, onLockSuccess, onLockFailed);
  };

  const onInputBlur = (e) => handleNumberInputBlur(e, setAmount);

  const onInputChange = (e) =>
    handleNumberInputChange(e, balanceInTokenContract, setAmount, () => {
      message.error(INVALID_TOKEN_AMOUNT_TO_LOCK);
    });

  return (
    <Spin spinning={isFetchingBalance}>
      <LockTokensRender
        onLockTokens={handleLockTokens}
        vechainBalance={balanceInTokenContract}
        amount={amount}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        address={address}
        setAmount={setAmount}
      />
    </Spin>
  );
}

export default LockTokensHOC;
