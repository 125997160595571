import { Card as AntCard } from 'antd';
import React from 'react';

function Card({ children, className = '', wrapper = false }) {
  if (wrapper)
    return (
      <div className="ts-signin-wrapper">
        <AntCard className={`ts-card ${className}`}>{children}</AntCard>
      </div>
    );
  return <AntCard className={`ts-card ${className}`}>{children}</AntCard>;
}

export default Card;
