import { Modal } from 'antd';
import React from 'react';
import SuccessGIF from '../assets/Success-Polkadot.gif';
import constants from '../constants/messages';
import { truncateAddress } from '../helpers/dataHelpers';

function TokenLockTransactionRender({
  txId,
  vechainBalance,
  lockedTokens,
  navigateToUpdatingStateRoot,
}) {
  const lockedTokensElement = (
    <>
      <span>
        {lockedTokens} {constants.TOKENS_TEXT}
      </span>
      {` ${constants.TOKENS_ARE_LOCKED_TEXT} `}
    </>
  );

  const totalVechainBalanceElement = (
    <>
      <span>
        {vechainBalance} {constants.TOKENS_TEXT}
      </span>
      {` ${constants.REMAINING_ON_VECHAIN}`}
    </>
  );

  return (
    <div className="ts-signin-wrapper">
      <Modal
        open
        footer={null}
        onCancel={navigateToUpdatingStateRoot}
        maskClosable={false}
        className="ts-lock-tokens-success-modal"
      >
        <br />
        <center>
          <img className="ts-response-gif" src={SuccessGIF} alt="Success GIF" />
          <h1 className="ts-lock-success-title">{constants.SUCCESS_TEXT}</h1>
          <div className="ts-lock-success-tx-section">
            {constants.TX_HASH_TEXT}
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_VECHAIN_TX_URL}/${txId}/`}
            >
              {truncateAddress(txId, 10)}
            </a>
          </div>
          <div className="ts-lock-success-tx-text">
            <br />
            <p>
              {lockedTokens && lockedTokensElement}
              {totalVechainBalanceElement}
            </p>
            <br />
            <p>{constants.PROVIDE_EMAIL_TEXT}</p>
            <br />
            <p className="ts-need-help-text">
              <a
                target="_blank"
                rel="noreferrer"
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
              >
                {constants.CONTACT_JUR_TEXT}
              </a>
              {` ${constants.HAVE_ANY_QUERIES_TEXT}`}
            </p>
          </div>
        </center>
      </Modal>
    </div>
  );
}

export default TokenLockTransactionRender;
