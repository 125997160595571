import { Input } from 'antd';
import React from 'react';
import { Card } from '../common';
import Button from '../common/Button';
import {
  CONFIRM_AND_SIGN,
  DONT_HAVE_DOT_WALLET,
  GET_ONE_HERE,
  LETS_GET_DOT_WALLET_READY,
  POLKADOT_ADDRESS,
} from '../constants/messages';

function PolkadotAddressFormRender({
  isSubmitting,
  onChange,
  onSubmit,
  lastStateRootUpdate,
}) {
  const AddressInput = (
    <Input
      className="ts-email-input"
      onChange={onChange}
      disabled={isSubmitting}
      placeholder="Enter your substrate address"
      onPressEnter={onSubmit}
    />
  );

  const SubmitButton = (
    <Button
      className="ts-email-button"
      disabled={isSubmitting}
      onClick={onSubmit}
    >
      {CONFIRM_AND_SIGN}
    </Button>
  );

  return (
    <div className="ts-signin-wrapper">
      <h1 className="ts-signin-title">{LETS_GET_DOT_WALLET_READY}</h1>
      {/* <h2 className="ts-signin-desc">{ENTER_ADDRESS_TO_CONTINUE}</h2> */}
      <h2 className="ts-signin-desc">
        Last State Root Update: {lastStateRootUpdate}
      </h2>
      <Card>
        <div className="ts-email-label">
          {POLKADOT_ADDRESS}
          <span>*</span>
        </div>
        {AddressInput}
        {SubmitButton}
      </Card>
      <p className="ts-need-help-text">
        {`${DONT_HAVE_DOT_WALLET} `}
        <a
          target="_blank"
          rel="noreferrer"
          href={process.env.REACT_APP_NEW_DOT_WALLET_HELP_URL}
        >
          {GET_ONE_HERE}
        </a>
      </p>
    </div>
  );
}

export default PolkadotAddressFormRender;
