import { message } from 'antd';
import React, { useEffect } from 'react';
import { SUBSTRATE_NODE_ERROR } from '../constants/messages';
import { ERROR_STATE, READY_STATE } from '../constants/states';
import { useSubstrateState } from '../substrate-lib';

function LoaderWrapper({ children }) {
  const { apiState } = useSubstrateState();

  useEffect(() => {
    if (apiState === ERROR_STATE) message.error(SUBSTRATE_NODE_ERROR);
  }, [apiState]);

  const loader = (
    <div className="ts-loader-wrapper">
      <div className="loader">
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
      </div>
    </div>
  );

  return apiState === READY_STATE ? children : loader;
}

export default LoaderWrapper;
