const VECHAIN_DECIMALS = 18;

// UI requirement to strip long addresses to e.g. 0x34c...852
export const truncateAddress = function (address = '', length = 3) {
  if (!address) return '';
  if (address.length / 2 < length) return address;
  return `${address.substring(0, length)}...${address.substring(
    address.length - length
  )}`;
};

export const parseVechainBalance = (balance) =>
  balance / Math.pow(10, VECHAIN_DECIMALS);

export const humanToVechain = (amount) => {
  // The line below is added to avoid linting errors for BigInt
  /* global BigInt */

  // Multiplying by integer and dividing later so that the multiplier when multiplying by 10^18 is an integer
  // Using floats instead of integers while multiplying with large numbers will causes issues; eg: 4.1 * 10^18 = 4099999999999999500

  const INTEGER_MULTIPLIER = 100; // (We're limiting amounts to 2 decimals)

  const integerAmount = parseInt(
    parseFloat(amount * INTEGER_MULTIPLIER).toFixed(2)
  );

  // Converting amount to BigInt before multiplying to handle large values
  amount = BigInt(integerAmount * Math.pow(10, VECHAIN_DECIMALS));
  amount = amount / BigInt(INTEGER_MULTIPLIER);

  // Below line is added to escape scientific notation for large numbers
  amount = amount.toLocaleString('fullwide', { useGrouping: false });

  amount = amount.toString();
  return amount;
};

const LAST_LOCKED_AT = 'last-locked-at';

export const setLastLockedAt = () => {
  localStorage.setItem(LAST_LOCKED_AT, JSON.stringify(Date.now()));
};

export const fetchLastLockedAt = () => {
  return JSON.parse(localStorage.getItem(LAST_LOCKED_AT));
};

export const clearLastLockedAt = () => {
  return localStorage.removeItem(LAST_LOCKED_AT);
};

export const roundAmount = ({ amount, noOfDecimals = 2 }) => {
  return (
    Math.round(amount * Math.pow(10, noOfDecimals)) / Math.pow(10, noOfDecimals)
  );
};
