import { message, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  INVALID_POLKADOT_ADDRESS,
  LOCK_TO_CONTINUE,
} from '../constants/messages';
import {
  CLAIM_TOKENS_ROUTE,
  LOCK_TOKENS_ROUTE,
  UPDATING_STATEROOT_ROUTE,
} from '../constants/routes';
import {
  TokenSwapContext,
  useTokenSwapContextState,
} from '../context/TokenSwapContext';
import { fetchLastLockedAt } from '../helpers/dataHelpers';
import { getSignerProof, loadProofDB } from '../helpers/ipfs';
import { isValidPolkadotAddress } from '../helpers/polkadotHelpers';
import { getIpfsHash } from '../helpers/substrate';
import { fetchDepositedAndClaimedAmounts } from '../helpers/tokenHelpers';
import {
  getBlockTimestamp,
  handleSyncSignJurAddress,
} from '../helpers/vechain';
import { useSubstrateState } from '../substrate-lib';
import PolkadotAddressFormRender from './PolkadotAddressFormRender';

function PolkadotAddressFormHOC() {
  const [address, setAddress] = useState('');
  const [lastStateRootUpdate, setLastStateRootUpdate] = useState('');
  const [isProofFetched, setIsProofFetched] = useState(false);

  const { api, apiState } = useSubstrateState();
  const { setIsSubmitting, setSignatureProof, setJurAddress, setIpfsProof } =
    useContext(TokenSwapContext);
  const { signatureProof, isSubmitting, vechainAddress, ipfsProof } =
    useTokenSwapContextState();

  const handleAddressInputChange = (e) => setAddress(e.target.value);

  const navigate = useNavigate();

  const onFailed = () => {
    setIsSubmitting(false);
    return;
  };

  useEffect(() => {
    if (signatureProof) {
      navigate(CLAIM_TOKENS_ROUTE);
    }
  }, [signatureProof, navigate]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!isValidPolkadotAddress(address)) {
      message.error(INVALID_POLKADOT_ADDRESS);
      setIsSubmitting(false);
      return;
    }
    let { signer, signature, encoded } = await handleSyncSignJurAddress(
      address,
      onFailed
    );

    const updatedSignature = {
      signature,
      encoded,
      signer,
      ...ipfsProof,
    };
    setSignatureProof(updatedSignature);

    setJurAddress(address);
    setIsSubmitting(false);
  };

  useEffect(() => {
    (async function () {
      const UNIX_MULTIPLIER = 1000;
      if (api && !ipfsProof) {
        const ipfsHash = await getIpfsHash(api);

        if (!ipfsHash) return;
        const proofDB = await loadProofDB(ipfsHash);

        if (proofDB.revision) {
          getBlockTimestamp(proofDB.revision).then((res) => {
            const blockTimestamp = res;
            const formattedTimestamp = blockTimestamp * UNIX_MULTIPLIER;
            const formattedDate = new Date(formattedTimestamp);
            setLastStateRootUpdate(formattedDate.toString());
          });
        }

        // Check if lastLockedAt timestamp is before the last update state root block,
        // if not show user the "Updating Stateroot" page
        const lastLockedAt = fetchLastLockedAt();
        if (lastLockedAt) {
          getBlockTimestamp(proofDB.revision).then((res) => {
            const blockTimestamp = res;
            // block timestamp is unix
            if (blockTimestamp * UNIX_MULTIPLIER < lastLockedAt) {
              navigate(UPDATING_STATEROOT_ROUTE);
            }
          });
        }

        if (!proofDB) return;
        const signerProof = await getSignerProof(proofDB, vechainAddress);

        setIpfsProof({
          ipfsHash,
          storageProof: signerProof.storageProof,
        });
      }

      setIsProofFetched(true);
    })();
  }, [api, apiState, vechainAddress, ipfsProof, setIpfsProof, navigate]);

  useEffect(() => {
    (async () => {
      const { deposited, claimed } = await fetchDepositedAndClaimedAmounts(
        vechainAddress,
        api
      );

      if (deposited === claimed) {
        message.warning(LOCK_TO_CONTINUE);
        navigate(LOCK_TOKENS_ROUTE);
      }
    })();
  }, [vechainAddress, api, navigate]);

  return (
    <Spin spinning={!isProofFetched}>
      <PolkadotAddressFormRender
        isSubmitting={isSubmitting}
        onChange={handleAddressInputChange}
        onSubmit={handleSubmit}
        lastStateRootUpdate={lastStateRootUpdate}
      />
    </Spin>
  );
}

export default PolkadotAddressFormHOC;
