import { message } from 'antd';

export function validateEmail(mail) {
  var pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return mail.match(pattern);
}

export const handleCopyAddress = (
  walletAddress,
  alertText = 'VeChain Address'
) => {
  navigator.clipboard
    .writeText(walletAddress)
    .then(() => message.success(`${alertText} copied to clipboard`))
    .catch(() => {
      alert('Failed to copy address!');
    });
};

export const handleNumberInputBlur = (e, onChange) => {
  if (!e.target.value) {
    onChange(0);
    return;
  }

  // parseFloat removes leading zeros
  onChange(parseFloat(e.target.value));
};

export const handleNumberInputChange = (
  e,
  maxInputValue,
  onChange,
  onGreaterThanMax
) => {
  const { value: inputValue } = e.target;

  if (!inputValue) {
    onChange(null);
    return;
  }

  if (inputValue > maxInputValue) {
    onGreaterThanMax();
    return;
  }

  // Following regex is to not allow more than two places after decimal
  if (!inputValue.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
    return;
  }

  const reg = /^-?\d*(\.\d*)?$/;
  if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
    onChange(inputValue);
  }
};

export const EMAIL_ADDRESS = 'email-address';

export const storeEmail = (email = '') => {
  localStorage.setItem(EMAIL_ADDRESS, JSON.stringify(email));
};

export const fetchEmail = () => {
  return JSON.parse(localStorage.getItem(EMAIL_ADDRESS)) || '';
};
