import React from 'react';
import ErrorGIF from '../assets/Error-Failed-Polkadot.gif';
import { Card } from '../common';
import { TAKING_LONGER_THAN_EXPECTED } from '../constants/messages';

function ClaimTokenFailed() {
  return (
    <Card wrapper>
      <br />
      <div className="ts-claim-token-failed-card">
        <img className="ts-response-gif" src={ErrorGIF} alt="Error GIF" />
        <div className="ts-lock-success-title">
          {TAKING_LONGER_THAN_EXPECTED}
        </div>
        <br />
        <div className="ts-lock-success-tx-text">
          <p>
            If you're experiencing difficulty, please send in a{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
            >
              support ticket
            </a>
            .
          </p>
        </div>
      </div>
    </Card>
  );
}

export default ClaimTokenFailed;
